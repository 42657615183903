<script lang='ts'>
	import { setContextClient } from '@urql/svelte';
	import SnackBar from '$theme/components/SnackBar.svelte'; 
	import { createPortal } from '$lib/actions/portal';
	import client from '$lib/gql/client';
	import { onMount } from 'svelte';
	import '../app.css';
	import Progress from '$theme/components/Progress.svelte';
	import { navigating } from '$app/stores';
	import { authUser } from '$lib/stores/app';

	export let data;

    authUser.set(data?.authUser);
    setContextClient(client());  
</script>

{#if $navigating}
	<Progress />
{/if}
<div class='bg-base-200'>
	<slot />
	<div use:createPortal={'modals'}></div>
	<SnackBar />
</div>